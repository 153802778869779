<template>
  <div class="login">
    <!-- 头部 -->
    <!-- <div class="header-left">
      <img src="../assets/images/logo.png" alt="" width="180" />
    </div>
    <div class="header-right">
      <a href="https://www.yunlankeji.com" style="color: #fff;" target="_blank"
        ><i class="el-icon-thumb"></i>官网</a
      >
    </div> -->
    <!-- 登录 -->
    <div class="login_form positiona">
      <el-row type="flex" align="middle">
        <el-col :span="12"> 
         <div style="margin-top:50px;">
            <img src="../assets/images/left.png" width="400">
         </div>
        </el-col>
        <el-col :span="12">
          <div class="form_tit textc f18">登&nbsp;录</div>
          <div class="form_group">
            <label class='blue'><i class="el-icon-user"></i></label>
            <input type="text" placeholder="输入用户名" v-model="username" />
          </div>
          <div class="form_group">
           <label class='blue'><i class="el-icon-lock"></i></label>
            <input
              type="password"
              placeholder="输入密码"
              v-model="password"
              @keyup.enter="enterSubmit"
            />
          </div>
          <div class="form_group1 flex align-center">
            <div style="border-bottom:1px solid #f2f2f2">
              <label class='blue'><i class="el-icon-key"></i></label>
              <input type="text" placeholder="输入验证码" v-model="code" />
            </div>
            <div class="ml10">
                <img
                  src="../assets/images/code.png"
                  alt=""
                  height="40"
                  width="120"
                />
              </div>
          </div>
          <div class="login_btn">
            <button class="f16 pointer fw700" @click="submit">登 录</button>
          </div>
          <div class="flex justify-between form_group1">
            <div class="text999 f12 pointer">忘记密码</div>
            <div class="text-blue f12 pointer">免费注册</div>
          </div></el-col
        >
      </el-row>
    </div>
    <!--备案号-->
    <!-- <div class="footer">All Rights Reserved 皖ICP备18013121号</div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      username: "",
      password: "",
      code: "",
    };
  },
  methods: {
    // 登录
    submit() {
      var that = this;

      if (!that.username) {
        that.$message({
          message: "请输入用户名",
          type: "warning",
        });
        return;
      }
      if (!that.password) {
        that.$message({
          message: "请输入密码",
          type: "warning",
        });
        return;
      }
      console.log(that.username);
      console.log(that.$md5(that.password));

      //登陆到首页
      that.$http
        .post("/userInfo/login", {
          loginName: that.username,
          password: that.$md5(that.password),
        })
        .then(function (response) {
          if(response.data.code==200){
            that.setLocalStorage(
              "token",
              response.data.code,
              30 * 60 * 1000
            );
            localStorage.setItem("loginName", that.username, 30 * 60 * 1000);
            localStorage.setItem("userInfo", JSON.stringify(response.data.data));
            localStorage.setItem("authToken", response.data.data.token);
            that.username = "";
            that.password = "";
            that.$router.push({ path: "/index" });
          }else{
             that.$message({
                message: response.data.message,
                type: "warning",
              });
          }
          
        });
    },
    // 回车事件
    enterSubmit() {
      this.submit();
    },

    setLocalStorage(key, value, expires) {
      // 保持到LocalStorage,expires单位是毫秒
      // expires = expires * 1000;
      let params = { key, value, expires };
      if (expires) {
        // 记录何时将值存入缓存，毫秒级
        var data = Object.assign(params, { startTime: new Date().getTime() });
        localStorage.setItem(key, JSON.stringify(data));
      } else {
        if (Object.prototype.toString.call(value) == "[object Object]") {
          value = JSON.stringify(value);
        }
        if (Object.prototype.toString.call(value) == "[object Array]") {
          value = JSON.stringify(value);
        }
        localStorage.setItem(key, value);
      }
    },
  },
  created() {},
};
</script>

<style  scoped>
.header-left {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 100;
}
.header-right {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 100;
  border: 1px solid #fff;
  border-radius: 20px;
  padding:5px 20px;
}
.main_box {
  height: calc(100vh - 70px);
  height: -webkit-calc(100vh - 70px);
  height: -moz-calc(100vh - 70px);
}

.header .text {
  padding: 0 10px;
  border-left: 1px solid #999;
}
.blue{
  color:#409eff;
}
.login {
  background: #409eff url("../assets/images/bg.png") no-repeat left bottom;
  background-size: 50%;
  height: 100vh;
}
.login_form {
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  left: 50%;
  background-color: #ffffff;
  width: 50%;
  box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  padding-bottom: 50px;
}
.login_form .form_tit {
  margin-top: 80px;
  color:#409eff;
  font-size: 30px;
  font-weight: bold;


}
.login_form .form_group {
  width: 80%;
  margin: 18px auto 0 auto;
  border-bottom: 1px solid #f2f2f2;
}
.login_form .form_group1{
  width: 80%;
  margin: 18px auto 0 auto;
}
.login_form input {
  height: 42px;
  border: none;
  outline: 0;
  padding-left: 15px;
  font-size: 12px;
  box-sizing: border-box;
  outline: none;
}
.login_form input ::placeholder {
  color: #333 !important;
}
.login_btn {
  height: 45px;
  background: #409eff;
  border-radius: 5px;
  width: 65%;
  transition: all 0.3s;
  margin: 0 auto;
  margin-top: 30px;
}
.login_btn button {
  width: 100%;
  height: 100%;
  color: #ffffff;
  background-color: transparent;
  outline: none;
  border: none;
}
.login_btn:hover {
  transform: translateY(-6px);
  box-shadow: 0px 7px 15px 0px rgba(0, 0, 0, 0.1);
}
.footer{
  position: absolute;
  bottom:20px;
  text-align: center;
  width:100%;
  color:#fff;
}
@media (max-width: 1440px) {
  .login_form .form_group {
    margin: 15px auto 0 auto;
  }
  .login_btn {
    margin-top: 20px;
  }
}
</style>

